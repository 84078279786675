import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Enrollment, UserType } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { ModalHistoryComponent } from '../../ui-components/modal-history/modal-history.component';
import { EnrollmentSingleComponent } from './enrollment-single/enrollment-single.component';
import { UserInfo } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { LoadingComponent } from '../../ui-components/loading/loading.component';
import { EnrollmentsStatisticsService } from 'src/app/shared/services/enrollment/enrollments-statistics.service';

@Component({
  selector: 'app-enrollment-modal',
  standalone: true,
  imports: [
    EnrollmentSingleComponent,
    FormsModule,
    JsonPipe,
    LoadingComponent,
    ModalHistoryComponent,
  ],
  templateUrl: './enrollment-modal.component.html',
  styleUrl: './enrollment-modal.component.scss',
})
export class EnrollmentModalComponent implements AfterViewInit {
  @Input() openWith?: { enrollment?: Enrollment; enrollmentId?: string };

  @Output() modalDismissedFired = new EventEmitter<boolean>();

  @ViewChild('modal') modal?: ModalHistoryComponent;

  Enrollment = Enrollment;
  UserType = UserType;

  enrollment?: Enrollment;
  loading = false;

  showAdvanced = false;

  isDemo = environment.isDemo;
  production = environment.production;

  authUser?: UserInfo;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private enrollmentsStatisticsService: EnrollmentsStatisticsService
  ) {}

  ngAfterViewInit() {
    if (this.openWith) {
      console.log('openWith', this.openWith);
      if (this.openWith.enrollment) {
        this.open(this.openWith.enrollment);
        this.cdr.detectChanges();
      } else if (this.openWith.enrollmentId) {
        this.loading = true;
        this.enrollmentsStatisticsService
          .loadSingle(this.openWith.enrollmentId)
          .then(enrollment => {
            this.loading = false;
            if (enrollment) {
              this.enrollment = enrollment;
              this.cdr.detectChanges();
            }
          });
          
        this.open();
      }
    }
  }

  open(enrollment?: Enrollment) {
    console.log('open enrollment', { enrollment });
    if (enrollment) {
      this.enrollment = enrollment;
    }

    this.modal?.show();
  }

  toggleShowAdvanced() {
    this.showAdvanced = !this.showAdvanced;
    this.cdr.detectChanges();
  }

  modalDismissed() {
    delete this.enrollment;
    this.modalDismissedFired.emit();
  }
}
