import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Consumer, Enrollment } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import {
  CurrencyPipe,
  DOCUMENT,
  DatePipe,
  NgFor,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faArrowUpRightFromSquare,
  faCalendarDays,
  faCheck,
  faCircleInfo,
  faDollarSign,
  faEllipsis,
  faEnvelope,
  faGraduationCap,
  faHandHoldingMedical,
  faLocationDot,
  faNoteMedical,
  faPhone,
  faPills,
  faSave,
  faSchool,
  faSignature,
  faStethoscope,
  faTimes,
  faTrashAlt,
  faUser,
  faUserRobot,
  faUserTie,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { LoadingComponent } from '../../../ui-components/loading/loading.component';
import { getCarrierInfo } from 'src/app/shared/misc/carrierInfo';
import { carrierMap } from '../../../commission-statistics/commission-statistics/carrier';
import { EnrollmentsStatisticsService } from 'src/app/shared/services/enrollment/enrollments-statistics.service';

@Component({
  selector: 'app-enrollment-single',
  standalone: true,
  imports: [
    CurrencyPipe,
    DatePipe,
    FaIconComponent,
    LoadingComponent,
    NgFor,
    NgIf,
    TitleCasePipe,
  ],
  templateUrl: './enrollment-single.component.html',
  styleUrl: './enrollment-single.component.scss',
})
export class EnrollmentSingleComponent implements OnInit, OnDestroy {
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faCalendarDays = faCalendarDays;
  faCircleInfo = faCircleInfo;
  faCheck = faCheck;
  faDollarSign = faDollarSign;
  faEllipsis = faEllipsis;
  faEnvelope = faEnvelope;
  faGraduationCap = faGraduationCap;
  faHandHoldingMedical = faHandHoldingMedical;
  faLocationDot = faLocationDot;
  faNoteMedical = faNoteMedical;
  faPhone = faPhone;
  faPills = faPills;
  faSave = faSave;
  faSchool = faSchool;
  faSignature = faSignature;
  faStethoscope = faStethoscope;
  faTimes = faTimes;
  faTrashAlt = faTrashAlt;
  faUser = faUser;
  faUserTie = faUserTie;
  faUserRobot = faUserRobot;
  faXmark = faXmark;

  production = environment.production;
  isDemo = environment.isDemo;

  @Input() consumerId?: string;
  @Input() enrollment?: Enrollment;

  planTypeMap = this.enrollmentsStatisticsService.planPlanTypeMap;
  policyTypeMap = this.enrollmentsStatisticsService.planPolicyTypeMap;

  private user?: Consumer;

  loading = true;
  enrollments?: Enrollment[];

  genderLookupMap: { [key: string]: string } = {
    M: 'Male',
    F: 'Female',
  };

  private carriers: {
    [key: string]: {
      name: string;
      color?: string;
      profileImage?: string;
    };
  } = {};

  carrierMap = carrierMap;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    private enrollmentsStatisticsService: EnrollmentsStatisticsService,
    private route: ActivatedRoute
  ) {
    if (!this.consumerId) {
      this.route.params.subscribe(params => {
        if (params['consumerId']) {
          this.consumerId = params['consumerId'];
        }
      });
    }
  }

  ngOnInit(): void {
    console.log('enrollment', this.enrollment);
    if (this.enrollment) {
      this.enrollments = [this.enrollment];
      this.loading = false;
      this.handleEnrollments();
    }
  }

  ngOnDestroy() {
    this.document.documentElement.style.setProperty(
      '--p',
      '0.402039 0.222416 269.045477'
    );
    this.document.documentElement.style.setProperty(
      '--pc',
      '0.880408 0.044483 269.045477'
    );
  }

  handleEnrollments() {
    if (this.enrollments) {
      for (const i of this.enrollments) {
        // const planId = i.plan?.displayId?.replace('-', '_');

        if (i.carrier?.id) {
          const carrier = getCarrierInfo(i.carrier.id);
          this.carriers[i.id] = carrier;

          if (carrier.colorOklch) {
            this.document.documentElement.style.setProperty(
              '--p',
              carrier.colorOklch
            );
          }
          if (carrier.textColorOklch) {
            this.document.documentElement.style.setProperty(
              '--pc',
              carrier.textColorOklch
            );
          }
        }

        // // show map
        // if (
        //   !this.showGoogleMaps &&
        //   i?.consumer?.address?.lat &&
        //   i?.consumer.address?.lng
        // ) {
        //   this.googleMapsOptions.center = {
        //     lat: i.consumer.address.lat,
        //     lng: i.consumer.address.lng,
        //   };
        //   this.googleMapsMarkerPositions = [
        //     {
        //       lat: i.consumer.address.lat,
        //       lng: i.consumer.address.lng,
        //     },
        //   ];
        //   this.showGoogleMaps = true;
        // }

        // // get docs
        // if (planId) {
        //   getDoc(doc(this.firestore, 'static', 'drugs', 'byPlan', planId)).then(
        //     doc => {
        //       const data = doc.data();
        //       this.loadingPlanDrugs = false;
        //       if (!this.planDrugs) {
        //         this.planDrugs = {};
        //       }
        //       this.planDrugs[i.id] = data?.['drugs'];
        //       console.log('Drugs', this.planDrugs);
        //     }
        //   );
        // }

        // if (i.pcp?.id && !environment.production) {
        //   this.searchService.getSingle(i.pcp.id, 'doctors').then(res => {
        //     console.log('res', res);

        //     if (!this.planDoc) {
        //       this.planDoc = {};
        //     }
        //     this.loadingDoc = false;
        //     this.planDoc[i.id] = res;
        //     this.cdr.detectChanges();
        //   });
        // }
      }
      this.cdr.detectChanges();
    }
  }

  // searchAndAddDrug(planId: string, drug: any) {
  //   console.log('add drug', planId, drug);
  //   if (!this.user?.id) {
  //     console.warn('No user id');
  //     return;
  //   }
  //   const planDrug = this.planDrugs?.[planId]?.find(
  //     d => d.rxcui === drug.rxcui
  //   );

  //   if (!this.drugs) {
  //     this.drugs = {};
  //   }
  //   if (!this.drugs[planId]) {
  //     this.drugs[planId] = [];
  //   }
  //   if (this.drugs && !this.drugs?.[planId]?.includes(drug)) {
  //     if (planDrug) {
  //       this.drugs[planId]?.push({
  //         ...planDrug,
  //         drug,
  //       });
  //     } else {
  //       this.drugs[planId]?.push({
  //         status: 'notCovered',
  //         drug,
  //       });
  //     }
  //     this.cdr.detectChanges();
  //   }

  //   console.log('drugs', this.drugs);

  //   if (environment.isDemo) return;

  //   setDoc(doc(this.firestore, 'consumers', this.user.id, 'static', 'drugs'), {
  //     drugs: deleteUndefined(this.drugs),
  //   });
  // }

  // removeDrug(planId: string, drug: any) {
  //   if (this.user?.id) {
  //     console.log('drug', drug);

  //     if (this.drugs) {
  //       this.drugs[planId] = this.drugs[planId]?.filter(
  //         d => d.drug.rxcui !== drug.drug.rxcui
  //       );
  //       this.cdr.detectChanges();
  //     }

  //     if (environment.isDemo) return;
  //     setDoc(
  //       doc(this.firestore, 'consumers', this.user.id, 'static', 'drugs'),
  //       {
  //         drugs: this.drugs,
  //       }
  //     );
  //   }
  // }

  // openEmail(email?: string) {
  //   if (!email) return;
  //   const window = getWindow();
  //   window.open(`mailto:${email}`);
  // }

  getActiveType(enrollment: Enrollment) {
    if (enrollment.details?.effectiveDate?.timestamp) {
      const now = new Date();
      const start = new Date(enrollment.details.effectiveDate.timestamp);

      if (start) {
        if (now < start) {
          return 'pending';
        } else {
          return 'active';
        }
      }
    }
    return undefined;
  }

  // openInNewTab(url?: string) {
  //   const window = getWindow();
  //   if (url) window.open(url, '_blank');
  // }

  // saveEmailOk(insurance: SfWe_Plan, changeEmail = false) {
  //   if (this.user) {
  //     this.consumerService
  //       .saveEmailOk(insurance, changeEmail, this.newEmail)
  //       .then(res => {
  //         console.log('res', res);
  //       });
  //   }
  // }

  getUserTitle() {
    if (this.enrollments) {
      if (this.enrollments[0]?.consumer?.firstName)
        return this.enrollments[0]?.consumer?.firstName;
    }
    return this.user?.firstName;
  }
}
