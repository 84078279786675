<app-modal-history
  [identifierGroupId]="enrollment?.identifiers?.identifierGroupId"
  [userId]="enrollment?.agency?.id"
  #modal
  (dismissed)="modalDismissed()">
  @if (enrollment) {
    <h3>Enrollment</h3>

    <app-enrollment-single [enrollment]="enrollment"></app-enrollment-single>

    @if (
      !isDemo && (!production || authService.authUserType === UserType.AGENCY)
    ) {
      <div class="form-control">
        <label class="label cursor-pointer gap-2 justify-start">
          <input
            type="checkbox"
            class="checkbox"
            [ngModel]="showAdvanced"
            (ngModelChange)="toggleShowAdvanced()" />
          <span class="label-text">Show Advanced Data</span>
        </label>
      </div>
    }

    @if (showAdvanced) {
      <!-- <button
        class="btn btn-outline btn-sm"
        type="button"
        (click)="retry(policyUpdate)">
        Retry processing
      </button> -->

      <div class="mockup-code">
        <pre><code class="select-all">{{
              Enrollment.toJSON(enrollment) | json
            }}</code></pre>
      </div>
    }
  } @else {
    <app-loading></app-loading>
  }
</app-modal-history>
