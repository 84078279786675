import { StatisticsKeys } from 'src/app/shared/components/commission-statistics/keys';
import { ChartType, KeyType } from 'src/app/shared/models/classes/charts';

export const bookOfBusinessKeys: StatisticsKeys = [
  {
    name: 'carrier-id',
    key: ['carrier', 'id'],
    // name: 'plan-carrierName',
    // key: ['plan', 'carrierName'],
    type: KeyType.STRING,
    title: 'Book of Business / Carrier Breakdown',
    autocomplete: true,
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-12',
    // view: [1200, 800],
    bigHeight: true,
    bigLegend: true,
    carrierColors: true,
    diagramArea: 'TOP',
  },
  {
    name: 'agency-id',
    key: ['agency', 'id'],
    additionalKeys: ['agency.name', 'agency.profileImage'],
    type: KeyType.STRING,
    title: 'Agency',
    autocomplete: true,
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-12',
    // view: [1200, 400],
    bigLegend: true,
    diagramArea: 'TOP',
  },
  {
    name: 'agent-id',
    key: ['agent', 'id'],
    additionalKeys: ['agent.firstName', 'agent.lastName', 'agent.profileImage'],
    type: KeyType.STRING,
    title: 'Agent',
    autocomplete: true,
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-12',
    // view: [1200, 400],
    bigLegend: true,
    hideDiagram: true,
    diagramArea: 'TOP',
  },

  {
    name: 'marketing-vendor',
    key: ['marketing', 'vendor'],
    type: KeyType.STRING,
    title: 'Marketing Vendors',
    autocomplete: true,
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-12',
    // view: [1200, 800],
    bigHeight: true,
    bigLegend: true,
    hideDiagram: true,
    diagramArea: 'TOP',
    hideFilter: true,
  },
  {
    name: 'plan-type',
    key: ['plan', 'type'],
    type: KeyType.STRING,
    title: 'Plan Type',
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-4',
  },
  {
    name: 'plan-snp',
    key: ['plan', 'snp'],
    type: KeyType.STRING,
    title: 'Plan SNP',
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-4',
  },
  {
    name: 'plan-planPolicyType',
    key: ['plan', 'planPolicyType'],
    type: KeyType.STRING,
    title: 'Plan Policy Type',
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-4',
  },
  // {
  //   name: 'leadSource',
  //   key: 'leadSource',
  //   type: KeyType.STRING,
  //   title: 'Lead Source',
  //   defaultChartType: ChartType.PIE_GRID,
  //   class: 'md:col-span-4',
  // },
  {
    name: 'okToEmail',
    key: 'okToEmail',
    type: KeyType.BOOL,
    title: 'Client Email Received',
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-6',
  },
  {
    name: 'medicaid-has',
    key: ['medicaid', 'has'],
    type: KeyType.BOOL,
    title: 'Client has Medicaid',
    defaultChartType: ChartType.PIE_GRID,
    class: 'md:col-span-6',
  },
  {
    name: 'pcp-designatePCP',
    key: ['pcp', 'designatePCP'],
    type: KeyType.BOOL,
    title: 'Client Designated PCP',
    defaultChartType: ChartType.PIE_GRID,
    class: 'md:col-span-6',
  },
  {
    name: 'pcp-establishedPatient',
    key: ['pcp', 'establishedPatient'],
    type: KeyType.BOOL,
    title: 'Client Established Patient',
    defaultChartType: ChartType.PIE_GRID,
    class: 'md:col-span-6',
  },

  {
    name: 'consumer-dateOfBirthYear',
    key: ['consumer', 'dateOfBirthYear'],
    type: KeyType.STRING,
    title: 'Client Date of Birth',
    defaultChartType: ChartType.LINE,
    class: 'md:col-span-6',
    // class: 'md:col-span-4',
    autocomplete: true,
    sortBy: 'key',
  },
  {
    name: 'consumer-gender',
    key: ['consumer', 'gender'],
    type: KeyType.STRING,
    title: 'Gender Mix',
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-6',
    // class: 'md:col-span-4',
  },
  {
    name: 'consumer-language',
    key: ['consumer', 'language'],
    type: KeyType.STRING,
    title: 'Client Primary Language',
    defaultChartType: ChartType.PIE_GRID,
    class: 'md:col-span-6',
    // class: 'md:col-span-4',
  },
  {
    name: 'veteranStatus',
    key: 'veteranStatus',
    type: KeyType.STRING,
    title: 'Veteran Status',
    defaultChartType: ChartType.NUMBER_CARD,
    class: 'md:col-span-12',
    hideDiagram: true,
    hideFilter: true,
  },
  {
    name: 'enrollmentData-policyStatus',
    key: ['enrollmentData', 'policyStatus'],
    type: KeyType.STRING,
    title: 'Policy Status',
    defaultChartType: ChartType.PIE_ADVANCED,
    class: 'md:col-span-6',
  },
  {
    name: 'consumer-homeAddress-zip',
    key: ['consumer', 'homeAddress', 'zip'],
    type: KeyType.STRING,
    title: 'Zip Code',
    hideDiagram: true,
    autocomplete: true,
    class: 'md:col-span-12',
  },
  {
    name: 'consumer-homeAddress-county',
    key: ['consumer', 'homeAddress', 'county'],
    type: KeyType.STRING,
    title: 'County',
    hideDiagram: true,
    autocomplete: true,
    class: 'md:col-span-12',
  },
  {
    name: 'consumer-homeAddress-state',
    key: ['consumer', 'homeAddress', 'state'],
    type: KeyType.STRING,
    title: 'State',
    hideDiagram: true,
    defaultChartType: ChartType.BAR_VERTICAL,
    autocomplete: true,
    class: 'md:col-span-6',
    scrollable: true,
  },
  {
    name: 'tags-currentTags',
    key: ['tags', 'currentTags'],
    type: KeyType.STRING,
    title: 'Current Tags',
    hideDiagram: true,
    defaultChartType: ChartType.BAR_VERTICAL,
    autocomplete: true,
    scrollable: true,
  },
  {
    name: 'tags-historicTags',
    key: ['tags', 'historicTags'],
    type: KeyType.STRING,
    title: 'Historic Tags',
    hideDiagram: true,
    defaultChartType: ChartType.BAR_VERTICAL,
    autocomplete: true,
    scrollable: true,
  },

  // {
  //   name: 'electionPeriod',
  //   key: 'electionPeriod',
  //   type: KeyType.STRING,
  //   title: 'Election Period',
  //   defaultChartType: ChartType.PIE_GRID,
  //   class: 'md:col-span-4',
  // },

  // {
  //   name: 'otherDrugCoverage-has',
  //   key: ['otherDrugCoverage', 'has'],
  //   type: KeyType.BOOL,
  //   title: 'Has Other Drug Coverage',
  //   defaultChartType: ChartType.PIE_ADVANCED,
  //   class: 'md:col-span-4',
  // },
  // {
  //   name: 'otherGroupCoverage-has',
  //   key: ['otherGroupCoverage', 'has'],
  //   type: KeyType.BOOL,
  //   title: 'Has Other Group Coverage',
  //   defaultChartType: ChartType.PIE_ADVANCED,
  //   class: 'md:col-span-4',
  // },
  // {
  //   name: 'paymentType',
  //   key: 'paymentType',
  //   type: KeyType.STRING,
  //   title: 'Payment Type',
  //   defaultChartType: ChartType.PIE_GRID,
  //   class: 'md:col-span-6',
  // },

  // {
  //   name: 'work',
  //   key: 'work',
  //   type: KeyType.BOOL,
  //   title: 'Work',
  //   defaultChartType: ChartType.PIE_ADVANCED,
  //   class: 'md:col-span-6',
  // },
  // {
  //   name: 'premium',
  //   key: 'premium',
  //   type: KeyType.PRICE,
  //   title: 'Premium',
  //   hideFilter: true,
  // },
];
