import { Injectable } from '@angular/core';
import { KeyType } from 'src/app/shared/models/classes/charts';
import { environment } from 'src/environments/environment';
import { takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ElasticService } from '../elastic.service';
import { Enrollment_BaseCategory, UserType } from '../../models';
import { selectPermissionsGranted } from '../../store/permission-granted/+state/permission-granted.selectors';
import { carrierMap } from '../../components/commission-statistics/commission-statistics/carrier';
import { bookOfBusinessKeys } from 'src/app/components/book-of-business/keys';
import { Enrollment } from '../../models/classes/enrollment';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentsStatisticsService {
  replacementOldCarrierName?: string;
  replacementNewCarrierName?: string;

  constructor(
    private elasticService: ElasticService,
    private store: Store,
    private toastService: ToastService
  ) {}

  loadAll = (
    filter: any,
    sizeCount = 0,
    from = 0,
    baseCategory?: Enrollment_BaseCategory
  ): Promise<any> =>
    new Promise(resolve => {
      console.log('Filter', filter);
      const query: any = {
        size: sizeCount,
        from: from,
        query: {
          bool: {
            must: [],
          },
        },
        aggs: {},
        track_total_hits: true,
      };

      const { index, keyMap } = this.buildQuery(query, filter, baseCategory);
      this.buildAggs(query);

      console.log('query', query);

      this.elasticService
        .query(index, query, environment.elasticPrefixBase)
        .then(async (res: any) => {
          console.log(res);

          const userMap: { [key: string]: any } = {};
          const data: { [key: string]: any[] } = {};
          // const dataMap: {
          //   [key: string]: { answers: { [key: string]: any }; total: number };
          // } = {};

          for (const key in res.aggregations) {
            // dataMap[key] = {
            //   answers: {},
            //   total: res.aggregations[key].sum_other_doc_count,
            // };
            data[key] = [];

            for (const b of res.aggregations[key].buckets) {
              if (keyMap[key]) {
                const title = this._getTitle(keyMap[key], b.key);
                const v: any = {
                  id: b.key,
                  name: title,
                  value: b.doc_count,
                };

                if (keyMap[key].additionalKeys) {
                  for (const k of keyMap[key].additionalKeys) {
                    const innerKey = k.split('.').pop();
                    v[innerKey] = b[k].buckets?.[0]?.key;
                  }
                }

                if (key === 'agent-id') {
                  v.name = v.firstName + ' ' + v.lastName;
                }

                data[key].push(v);
              }
            }
          }

          const agencyMap: { [key: string]: any } = await new Promise(
            resolve => {
              this.store
                .select(selectPermissionsGranted)
                .pipe(
                  takeWhile(x => x.loading || !x.permissionsGranted || true)
                )
                .subscribe(permissions => {
                  if (!permissions.loading && permissions?.permissionsGranted) {
                    const agencyMap: any = {};
                    for (const agency of permissions.permissionsGranted) {
                      if (agency.user?.id) {
                        agencyMap[agency.user.id] = agency.user;
                        userMap[agency.user.id] = agency.user;
                      }
                    }
                    resolve(agencyMap);
                  }
                });
            }
          );

          if (data['agency-id']) {
            for (let i = 0; i < data['agency-id'].length; i++) {
              if (
                !data['agency-id'][i].name &&
                agencyMap[data['agency-id'][i].id]?.name
              ) {
                data['agency-id'][i].name =
                  agencyMap[data['agency-id'][i].id]?.name;
                userMap[data['agency-id'][i].id]?.name;
              } else {
                data['agency-id'][i].name = 'Agency ' + (i + 1);
              }
            }
          }

          if (data['agent-id']) {
            for (let i = 0; i < data['agent-id'].length; i++) {
              userMap[data['agent-id'][i].id] = data['agent-id'][i];
            }
          }

          if (data['carrier-id']) {
            for (let i = 0; i < data['carrier-id'].length; i++) {
              if (carrierMap[data['carrier-id'][i].id]) {
                data['carrier-id'][i] = {
                  ...data['carrier-id'][i],
                  ...carrierMap[data['carrier-id'][i].id],
                };
              }
            }
          }

          // if (data['signaturePeriod']) {
          //   data['signaturePeriod'] = data['signaturePeriod'].filter(
          //     x => x.id !== 'SP_UNKNOWN'
          //   );
          // }
          if (data['carrier-id']) {
            for (let i = 0; i < data['carrier-id'].length; i++) {
              if (carrierMap[data['carrier-id'][i].id]) {
                data['carrier-id'][i] = {
                  ...data['carrier-id'][i],
                  ...carrierMap[data['carrier-id'][i].id],
                };
              }
            }
          }

          const filterLocationList = res.aggregations.filterLocationList.buckets
            .map((b: any) => {
              return {
                state: b.key.state,
                county: b.key.county,
                zip: b.key.zip,
                count: b.doc_count,
              };
            })
            .sort((a: any, b: any) => b.count - a.count);

          const byStateCarrierType =
            res.aggregations.byStateCarrierType.buckets.map((b: any) => {
              return {
                name: b.key,
                count: b.doc_count,
                carriers: b.carriers.buckets.map((b2: any) => {
                  return {
                    name: b2.key,
                    count: b2.doc_count,
                    types: b2.types.buckets.reduce((acc: any, b3: any) => {
                      acc[b3.key] = {
                        name: b3.key,
                        count: b3.doc_count,
                      };

                      return acc;
                    }, {}),
                  };
                }),
                types: b.carriers.buckets.reduce((acc: any, b2: any) => {
                  for (const key in b2.types.buckets) {
                    const b3 = b2.types.buckets[key];

                    if (acc[b3.key]) {
                      acc[b3.key].count += b3.doc_count;
                    } else {
                      acc[b3.key] = {
                        name: b3.key,
                        count: b3.doc_count,
                      };
                    }
                  }

                  return acc;
                }, {}),
              };
            });

          const byPolicyStatusCategory =
            res.aggregations.byPolicyStatusCategory.buckets.map((b: any) => {
              return {
                name: this.planPolicyStatusCategoryLookupMap[b.key] ?? b.key,
                count: b.doc_count,
                percentage: b.doc_count / res.hits.total.value,
                statuses: b.statuses.buckets.map((b2: any) => {
                  return {
                    name: this.planPolicyStatusLookupMap[b2.key] ?? b2.key,
                    count: b2.doc_count,
                    percentage:
                      // b2.doc_count / b.doc_count,
                      b2.doc_count / res.hits.total.value,
                  };
                }),
              };
            });

          const byStateMapped = res.aggregations.byStateMapped.buckets.map(
            (b: any) => {
              return {
                name: b.key,
                count: b.doc_count,
                county: b.county.buckets.map((b2: any) => {
                  return {
                    name: b2.key,
                    count: b2.doc_count,
                    zip: b2.zip.buckets.map((b3: any) => {
                      return {
                        name: b3.key,
                        count: b3.doc_count,
                      };
                    }),
                  };
                }),
              };
            }
          );

          let veteransCount = 0;
          if (data['consumer-veteranStatus']) {
            for (const b of data['consumer-veteranStatus']) {
              if (
                b.id === 'VS_SELF' ||
                b.id === 'VS_SPOUSE' ||
                b.id === 'VS_DEPENDENT'
              ) {
                veteransCount += b.value;
              }
            }
          }

          const d = {
            source: [],
            data,
            // dataMap,
            // dataByKey,
            filterLocationList,
            byStateCarrierType,
            byPolicyStatusCategory,
            byStateMapped,
            // heatmap: res.aggregations.heatmap.buckets.map((b: any) => {
            //   const geohashCenter = decodeGeohash(b.key); // Implement this function to decode geohash into LatLng
            //   return {
            //     location: new google.maps.LatLng(
            //       geohashCenter.lat,
            //       geohashCenter.lon
            //     ),
            //     weight: b.doc_count,
            //   };
            // }),
            planTypeMap: data['plan-planType']?.reduce((acc: any, b: any) => {
              acc[b.name] = b.value;
              return acc;
            }, {}),
            snpMap: data['plan-snp']?.reduce((acc: any, b: any) => {
              acc[b.name] = b.value;
              return acc;
            }, {}),
            totalEnrollments: res.hits.total.value,
            userMap,
            veteransCount,
          };

          if (sizeCount > 0) {
            d['source'] = res.hits?.hits?.map((x: any) => {
              const data = x._source;
              // if (!data.id) {
              data.id = x._id;
              // }

              data.baseCategory = baseCategory;
              if (!data.baseCategory) {
                data.baseCategory = Enrollment_BaseCategory.BC_MEDICARE;
              }
              return data;
            });
          }

          resolve(d);
        })
        .catch(err => {
          console.log('error', err);
          this.toastService.error('An error occurred', 'Please try again.');
        });
    });

  buildQuery = (
    query: any,
    filter: any,
    baseCategory?: Enrollment_BaseCategory,
    ignoreAggs = false
  ) => {
    this.applyDateFilter(
      query,
      filter.signatureDate,
      filter.signatureDateRange,
      'details.signatureDate.timestamp',
      'details.signaturePeriod'
    );

    this.applyDateFilter(
      query,
      filter.date,
      filter.dateRange,
      'metadata.startTimestamp'
    );

    if (filter.effectiveDateMonth && filter.effectiveDateMonth.length > 0) {
      query.query.bool.must.push({
        bool: {
          should: filter.effectiveDateMonth.map((x: any) => {
            return {
              match: {
                'plan.effectiveDateMonth': x.id,
              },
            };
          }),
        },
      });
    }
    if (filter.effectiveDateYear && filter.effectiveDateYear.length > 0) {
      query.query.bool.must.push({
        bool: {
          should: filter.effectiveDateYear.map((x: any) => {
            return {
              match: {
                'plan.effectiveDateYear': x.id,
              },
            };
          }),
        },
      });
    }

    const keyMap: { [key: string]: any } = {};

    let keys;
    let index;

    switch (baseCategory) {
      // case Enrollment_BaseCategory.BC_ANNUITY:
      //   index = 'consumers_enrollments_lf';
      //   keys = keysLife;

      //   query.query.bool.must.push({
      //     match: {
      //       'plan.type': 'Annuity',
      //     },
      //   });
      //   break;
      // case Enrollment_BaseCategory.BC_HEALTH:
      //   index = 'consumers_enrollments_lf';
      //   keys = keysLife;

      //   query.query.bool.must.push({
      //     match: {
      //       'plan.type': 'Health',
      //     },
      //   });
      //   break;
      // case Enrollment_BaseCategory.BC_LIFE:
      // index = 'consumers_enrollments_lf';
      // keys = keysLife;

      // if (environment.production) {
      //   keys = v002KeysLife;
      //   query.query.bool.must.push({
      //     bool: {
      //       should: [
      //         {
      //           match: {
      //             'plan.type': 'Annuity',
      //           },
      //         },
      //         {
      //           match: {
      //             'plan.type': 'Health',
      //           },
      //         },
      //         {
      //           match: {
      //             'plan.type': 'Life',
      //           },
      //         },
      //       ],
      //     },
      //   });
      // } else {
      //   query.query.bool.must.push({
      //     match: {
      //       'plan.type': 'Life',
      //     },
      //   });
      // }
      // break;
      default:
        keys = bookOfBusinessKeys;
        index = 'agencies_enrollments';

      //!TODO
    }

    for (const k of keys) {
      let key: string;
      if (k.key instanceof Array) {
        key = k.key.join('.');
      } else {
        key = k.key;
      }

      keyMap[k.name] = k;

      if (!ignoreAggs) {
        query.aggs[k.name] = {
          terms: {
            field: key,
            size: 10000,
          },
        };

        if (k.sortBy === 'key') {
          query.aggs[k.name].terms.order = {
            _key: 'asc',
          };
        }

        for (const key of k.additionalKeys || []) {
          if (!query.aggs[k.name].aggs) {
            query.aggs[k.name].aggs = {};
          }
          query.aggs[k.name].aggs[key] = {
            terms: {
              field: key,
              size: 10000,
            },
          };
        }
      }
      if (
        filter[k.name] &&
        filter[k.name].length > 0 &&
        k.name !== 'agency-id'
      ) {
        console.log('filter', k.name, filter[k.name]);
        if (k.type === KeyType.K_DATE) {
          if (k.name === 'consumer-birthDate') {
            const splitted = filter[k.name].split('-');
            const year = parseInt(splitted[0]);
            const month = parseInt(splitted[1]);
            const day = parseInt(splitted[2]);

            if (year > 1000) {
              query.query.bool.must.push({
                bool: {
                  must: [
                    {
                      match: {
                        'consumer.birthDate.year': year,
                      },
                    },
                    {
                      match: {
                        'consumer.birthDate.month': month,
                      },
                    },
                    { match: { 'consumer.birthDate.day': day } },
                  ],
                },
              });
            }
          }
        } else {
          query.query.bool.must.push({
            bool: {
              should: filter[k.name].map((x: any) => {
                return {
                  match: {
                    [key]: x.id,
                  },
                };
              }),
            },
          });
        }
      }
    }

    // if (filter['executive-id']) {
    //   query.query.bool.must.push({
    //     bool: {
    //       should: filter['executive-id'].map((x: any) => {
    //         return {
    //           match: {
    //             ['executive.aggregatedExecutiveRule.executiveLadder']: x.id,
    //           },
    //         };
    //       }),
    //     },
    //   });
    // }

    // if (filter['executive-id-exclusive']) {
    //   const shouldValues = [];

    //   for (const x of filter['executive-id-exclusive']) {
    //     shouldValues.push({
    //       match: {
    //         ['executive.tierFilterList.indexKey']: `${x.id}#-#t0#-#SELF#-#HC#-#${x.id}`,
    //       },
    //     });
    //   }

    //   query.query.bool.must.push({
    //     bool: {
    //       should: shouldValues,
    //     },
    //   });
    // }

    if (filter['agency-filter-id']) {
      let shouldValues = [];
      if (filter['agency-filter-type'] === 'DIRECT') {
        for (const x of filter['agency-filter-id']) {
          shouldValues.push({
            match: {
              ['executive.tierFilterList.indexKey']: `${x.id}#-#t0#-#SELF#-#HC#-#${x.id}`,
            },
          });
        }
      } else {
        shouldValues = filter['agency-filter-id'].map((x: any) => {
          return {
            match: {
              ['executive.aggregatedExecutiveRule.executiveLadder']: x.id,
            },
          };
        });
      }

      query.query.bool.must.push({
        bool: {
          should: shouldValues,
        },
      });
    }

    if (filter['order']) {
      query.sort = [
        '_score',
        { [filter['order'].field]: filter['order'].direction ?? 'asc' },
      ];
    }

    if (filter['consumer-name']) {
      query.query.bool.must.push({
        multi_match: {
          query: filter['consumer-name'],
          type: 'most_fields',

          fields: ['consumer.firstName', 'consumer.lastName', 'consumer.id'],
        },
      });
    }

    return {
      index,
      keyMap,
    };
  };

  buildAggs = (query: any) => {
    // query.aggs['executiveLadder'] = {
    //   terms: {
    //     field: `executive.tierFilterList.viewerUserId`,
    //     size: 10000,
    //   },
    //   aggs: {
    //     tier: {
    //       terms: {
    //         field: 'executive.tierFilterList.tier',
    //         size: 10000,
    //       },
    //       aggs: {
    //         userId: {
    //           terms: {
    //             field: 'executive.tierFilterList.id',
    //             size: 10000,
    //           },
    //         },
    //       },
    //     },
    //   },
    // };

    query.aggs['filterLocationList'] = {
      composite: {
        size: 10000,
        sources: [
          { state: { terms: { field: 'consumer.address.state' } } },
          { county: { terms: { field: 'consumer.address.county' } } },
          { zip: { terms: { field: 'consumer.address.zip' } } },
        ],
      },
    };

    query.aggs['byStateCarrierType'] = {
      terms: {
        field: 'consumer.address.state',
      },
      aggs: {
        carriers: {
          terms: {
            field: 'carrier.id',
            size: 10000,
          },
          aggs: {
            types: {
              terms: {
                field: 'plan.planType',
                size: 10000,
              },
            },
          },
        },
      },
    };

    query.aggs['byPolicyStatusCategory'] = {
      terms: {
        field: 'details.policyStatusCategory',
      },
      aggs: {
        statuses: {
          terms: {
            field: 'details.policyStatus',
            size: 10000,
          },
        },
      },
    };

    query.aggs['byStateMapped'] = {
      terms: {
        field: 'consumer.address.state',
      },
      aggs: {
        county: {
          terms: {
            field: 'consumer.address.county',
            size: 10000,
          },
          aggs: {
            zip: {
              terms: {
                field: 'consumer.address.zip',
                size: 10000,
              },
            },
          },
        },
      },
    };

    // query.aggs['heatmap'] = {
    //   geohash_grid: {
    //     field: 'consumer.location.coordinates',
    //     precision: 6,
    //   },
    // };
  };

  loadExecutives = (
    filter: any,
    executiveId: string,
    baseCategory?: Enrollment_BaseCategory,
    userType?: UserType
  ): Promise<any> =>
    new Promise(resolve => {
      const query: any = {
        size: 0,
        query: {
          bool: {
            must: [],
          },
        },
        aggs: {},
        track_total_hits: true,
      };

      const { index } = this.buildQuery(query, filter, baseCategory, true);

      if (executiveId) {
        // query.aggs['executiveLadderMap'] = {
        //   terms: {
        //     field: `executive.tierMap.${executiveId}.t0.id`,
        //     size: 10000,
        //   },
        // };
        let includeString = `${executiveId}\\#-\\#.*`;

        switch (userType) {
          case UserType.AGENCY:
            // includeString = `${executiveId}\\#-\\#AGENCY\\#-\\#HC.*`;
            includeString = `${executiveId}\\#-\\#t0\\#-\\#?(AGENCY|SELF)\\#-\\#HC.*`;
            break;
          case UserType.AGENT:
            includeString = `${executiveId}\\#-\\#t0\\#-\\#?(AGENT|SELF)\\#-\\#HC.*`;
            break;
        }

        console.log('includeString', includeString);

        query.aggs['executiveLadderKey'] = {
          terms: {
            field: `executive.tierFilterList.indexKey`,
            size: 10000,
            include: includeString,
          },
        };
      }

      this.elasticService
        .query(index, query, environment.elasticPrefixBase)
        .then(async (res: any) => {
          console.log('executive res', res);

          const data: { [key: string]: any[] } = {};

          const executiveLadderMap: { [key: string]: any } =
            await this.buildExecutiveLadderMap(res, userType);

          const d = {
            source: [],
            data,
            executiveLadderMap,
            totalEnrollments: res.hits.total.value,
          };

          resolve(d);
        });
    }).catch(err => {
      console.log('error', err);
      this.toastService.error('An error occurred', 'Please try again.');
    });

  // loadSingleEnrollment = (
  //   enrollmentId: string,
  //   useLifeIndex = false
  // ): Promise<any> =>
  //   new Promise(resolve => {
  //     const query: any = {
  //       query: {
  //         bool: {
  //           must: [
  //             {
  //               match: {
  //                 id: enrollmentId,
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     };

  //     let index = 'consumers_enrollments_sf';
  //     if (useLifeIndex) {
  //       index = 'consumers_enrollments_lf';
  //     }

  //     this.elasticService.query(index, query).then(async (res: any) => {
  //       console.log(res);

  //       const d = res.hits?.hits?.map((x: any) => {
  //         const data = x._source;
  //         if (!data.id) {
  //           data.id = x._id;
  //         }
  //         return data;
  //       });

  //       resolve(d);
  //     });
  //   });

  genderLookupMap: { [key: string]: string } = {
    M: 'Male',
    F: 'Female',
  };

  planPolicyTypeMap: { [key: string]: string } = {
    P_HMO: 'HMO',
    P_LPPO: 'LPPO',
    P_PDP: 'PDP',
    P_PPO: 'PPO',
  };

  planPlanTypeMap: { [key: string]: string } = {
    PT_MA: 'MA',
    PT_MAPD: 'MAPD',
    PT_PDP: 'PDP',
  };

  planPolicyStatusCategoryLookupMap: { [key: string]: string } = {
    PCAT_ACTIVE: 'Active',
    PCAT_INACTIVE: 'Inactive',
  };

  planPolicyStatusLookupMap: { [key: string]: string } = {
    // [SfWe_PolicyStatus.POL_PENDING]: 'Pending',
    // [SfWe_PolicyStatus.POL_SUBMITTED]: 'Submitted',

    // [SfWe_PolicyStatus.POL_PAID]: 'Paid',
    // [SfWe_PolicyStatus.POL_APPROVED]: 'Approved',
    // [SfWe_PolicyStatus.POL_INFORCE]: 'Inforce',

    // [SfWe_PolicyStatus.POL_LAPSED]: 'Lapsed',
    // [SfWe_PolicyStatus.POL_TERMINATED]: 'Terminated',
    // [SfWe_PolicyStatus.POL_DECEASED]: 'Deceased',

    // [SfWe_PolicyStatus.POL_LOST]: 'Lost',
    // [SfWe_PolicyStatus.POL_REVERSED]: 'Reversed',
    POL_PENDING: 'Pending',
    POL_SUBMITTED: 'Submitted',

    POL_PAID: 'Paid',
    POL_APPROVED: 'Approved',
    POL_INFORCE: 'Inforce',

    POL_LAPSED: 'Lapsed',
    POL_TERMINATED: 'Terminated',
    POL_DECEASED: 'Deceased',

    POL_LOST: 'Lost',
    POL_REVERSED: 'Reversed',
    POL_DECLINED: 'Declined',
  };

  veteranLookupMap: { [key: string]: { title: string; subTitle: string } } = {
    NOT_VETERAN: {
      title: 'No',
      subTitle: 'Veteran',
    },
    VS_SELF: {
      title: 'Consumer is Veteran',
      subTitle: 'Veteran',
    },
    VS_SPOUSE: {
      title: 'Spouse is Veteran',
      subTitle: 'Veteran',
    },
    VS_NO_ANSWER: {
      title: 'No Answer',
      subTitle: 'Veteran',
    },
    VS_DEPENDENT: {
      title: 'Dependent',
      subTitle: 'Veteran',
    },
  };

  private _getTitle = (cat: any, b: any) => {
    if (cat?.type === KeyType.BOOL) {
      if (b === true || b === 1) {
        return 'Yes';
      } else if (b === false || b === 0) {
        return 'No';
      }
    } else if (cat?.name === 'details-policyStatusCategory') {
      return this.planPolicyStatusCategoryLookupMap[b];
    } else if (cat?.name === 'details-policyStatus') {
      return this.planPolicyStatusLookupMap[b];
    } else if (cat?.name === 'plan-policyType') {
      return this.planPolicyTypeMap[b];
    } else if (cat?.name === 'plan-planType') {
      return this.planPlanTypeMap[b];
      // } else if (cat?.name === 'plan-category') {
      //   return this.planCategoryLookupMap[b];
    } else if (cat?.name === 'consumer-gender') {
      return this.genderLookupMap[b];
    } else if (cat?.name === 'veteranStatus') {
      return this.veteranLookupMap[b]?.title ?? b;
    }
    return b;
  };

  applyDateFilter = (
    query: any,
    date: string,
    dateRange: any,
    field: string,
    signaturePeriodField?: string
  ) => {
    const daysSince2023 = Math.floor(
      (new Date().getTime() - new Date('2023-12-31').getTime()) /
        (1000 * 3600 * 24)
    );

    const subtractMoreDays = environment.isDemo ? daysSince2023 : 0;

    console.log('subtractMoreDays', subtractMoreDays);

    if (date) {
      switch (date) {
        case 'AEP':
        case 'OEP':
        case 'ROY':
          if (signaturePeriodField) {
            query.query.bool.must.push({
              match: {
                [signaturePeriodField]: date,
              },
            });
          } else {
            throw new Error('signaturePeriodField is required for ' + date);
          }
          break;

        case 'today':
          {
            const startDate = new Date();
            startDate.setHours(0, 0, 0, 0);

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate.getTime(),
                },
              },
            });
          }
          break;
        case 'yesterday':
          {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 1);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date();
            endDate.setDate(endDate.getDate() - 1);
            endDate.setHours(23, 59, 59, 999);

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate.getTime(),
                  lte: endDate.getTime(),
                },
              },
            });
          }
          break;
        case 'last7days':
          {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - subtractMoreDays - 7);
            startDate.setHours(0, 0, 0, 0);

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate.getTime(),
                },
              },
            });
          }
          break;
        case 'last30days':
          {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - subtractMoreDays - 30);
            startDate.setHours(0, 0, 0, 0);

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate.getTime(),
                },
              },
            });
          }
          break;
        case 'last90days':
          {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - subtractMoreDays - 90);
            startDate.setHours(0, 0, 0, 0);

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate.getTime(),
                },
              },
            });
          }
          break;
        case 'last365days':
          {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - subtractMoreDays - 365);
            startDate.setHours(0, 0, 0, 0);

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate.getTime(),
                },
              },
            });
          }
          break;
        case 'custom':
          {
            if (!dateRange?.start || !dateRange?.end) {
              console.log('Please select a start and end date');
              return;
            }

            const startDate = dateRange.start
              ? new Date(dateRange.start)
              : undefined;

            if (startDate && environment.isDemo) {
              startDate.setDate(startDate.getDate() - subtractMoreDays);
            }

            if (startDate) {
              startDate.setHours(0, 0, 0, 0);
            }

            const endDate = dateRange.end ? new Date(dateRange.end) : undefined;

            //set end of day
            if (endDate) {
              endDate.setHours(23, 59, 59, 999);
            }

            if (endDate && environment.isDemo) {
              endDate.setDate(endDate.getDate() - subtractMoreDays);
            }

            query.query.bool.must.push({
              range: {
                [field]: {
                  gte: startDate?.getTime(),
                  lte: endDate?.getTime(),
                },
              },
            });
          }
          break;
      }
    }
  };

  buildExecutiveLadderMap = async (
    elasticRes: any,
    filterUserType?: UserType
  ) => {
    const executiveLadderAgg = elasticRes.aggregations.executiveLadderKey;

    const t0Map: { [key: string]: any } = {};
    const t0ChildrenMap: { [key: string]: any } = {};

    for (const b of executiveLadderAgg.buckets) {
      const key = b.key;
      const [viewerId, tier, userType, hasChildrenKey, userId] =
        key.split('#-#');

      const hasChildren = hasChildrenKey === 'HC';

      // if (tier === 't0' && userType !== 'SELF') {
      if (tier === 't0') {
        if (!hasChildren) {
          if (!t0ChildrenMap[viewerId]) {
            t0ChildrenMap[viewerId] = {};
          }
          t0ChildrenMap[viewerId][userId] = {
            id: userId,
            userType,
            count: b.doc_count,
            hasChildren,
          };
        } else {
          // if (
          //   !filterUserType ||
          //   userType === 'SELF' ||
          //   userTypeToJSON(filterUserType) === userType
          // ) {
          if (!t0Map[viewerId]) {
            t0Map[viewerId] = {};
          }
          t0Map[viewerId][userId] = {
            id: userId,
            userType,
            count: b.doc_count,
            hasChildren,
          };
          // }
        }
      }
    }

    console.log('t0ChildrenMap', t0ChildrenMap);
    console.log('t0Map', t0Map);

    const list = [];
    for (const viewerId in t0Map) {
      const res: any = this.mapExecutiveLadderDeep(
        t0Map,
        t0ChildrenMap,
        viewerId
      );

      list.push(res);
    }

    return list;

    // for(const b of executiveLadderAgg.buckets) {
    //   const viewerId = b.key

    //   for(const t of b.tier.buckets) {
    //     const tier = t.key

    //     for(const u of t.userId.buckets) {
    //       const userId = u.key

    //       t0Map[userId] = {
    //         viewerId,
    //         tier
    //       }
    //     }
    //   }
  };

  mapExecutiveLadderDeep = (
    t0Map: any,
    t0ChildrenMap: any,
    viewerId: string
  ) => {
    const viewer = t0Map[viewerId];
    const viewerChildren = t0ChildrenMap[viewerId];

    const viewerObj: any = {
      id: viewerId,
      children: [],
      singleChildren: [],
      count: 0,
    };

    console.log('viewer', viewer);

    for (const userId in viewer) {
      let user = viewer[userId];
      console.log('user', user);

      if (t0Map[userId] && t0Map[userId].userType !== 'SELF') {
        if (t0Map[userId].hasChildren) {
          user = this.mapExecutiveLadderDeep(t0Map, t0ChildrenMap, userId);
          // for (const child of user.children) {
          //   user.count += child.count;
          // }
        } else {
          // ownAgencyViewerObj.children.push(user);
          console.warn('ownAgencyViewerObj', t0Map[userId]);
        }
      }

      viewerObj.children.push(user);
      viewerObj.count += user.count;
    }

    for (const userId in viewerChildren) {
      let user = viewerChildren[userId];
      console.log('user', user);

      if (t0Map[userId] && t0Map[userId].userType !== 'SELF') {
        if (t0Map[userId].hasChildren) {
          user = this.mapExecutiveLadderDeep(t0Map, t0ChildrenMap, userId);
          // for (const child of user.children) {
          //   user.count += child.count;
          // }
        } else {
          // ownAgencyViewerObj.children.push(user);
          console.warn('ownAgencyViewerObj', t0Map[userId]);
        }
      }

      viewerObj.singleChildren.push(user);
      viewerObj.count += user.count;
    }

    return viewerObj;
  };

  loadSingle = (id: string): Promise<Enrollment | null> => {
    return new Promise(resolve => {
      this.elasticService
        .query(
          'agencies_enrollments',
          {
            query: {
              bool: {
                must: [
                  {
                    match: {
                      _id: id,
                    },
                  },
                ],
              },
            },
          },
          environment.elasticPrefixBase
        )
        .then(res => {
          console.log('res', res);
          const data = res.hits?.hits?.[0]?._source;

          if (!data) {
            resolve(null);
            return;
          }

          if (!data.id) {
            data.id = res.hits?.hits?.[0]?._id;
          }

          resolve(Enrollment.fromJSON(data));
        })
        .catch(err => {
          console.log('error', err);
          this.toastService.error('An error occurred', 'Please try again.');
        });
    });
  };
}
